import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { tapResponse } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  Email,
  ForgotTokenValidation,
  RequestLogin,
  TokenRefresh,
  ToolsApi,
  UserApi,
} from '../../api/api-sdk';
import { IUser } from '../../model/user';
import { UiService } from '../../services/ui.service';
import { AdminActions } from '../admin/actions';
import { AuthActions } from './actions';
import { authFeature } from './feature';

@Injectable()
export class AuthEffects {
  private readonly userPersistentKey = 'sigrow:user';

  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.initAuth),
      // TODO: refactor this
      filter(() =>
        ['/auth/newPassword', '/auth/explicitLogin'].every(
          (path) => path !== window.location.pathname,
        ),
      ),
      map(() => {
        const cachedUser = localStorage.getItem(this.userPersistentKey);
        if (cachedUser) {
          const user: IUser = JSON.parse(cachedUser!);
          return AuthActions.restoreUser({ user });
        } else {
          return AuthActions.forceLogin();
        }
      }),
    );
  });

  restoreUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.restoreUser),
      take(1),
      map(() => AuthActions.refreshToken({ isRoutine: false })),
    );
  });

  loginWithCredentials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginWithCredentials),
      exhaustMap((action) =>
        this.userApi
          .loginCreate(
            new RequestLogin({
              email: action.userName,
              password: action.password,
            }),
          )
          .pipe(
            map((response) =>
              AuthActions.loginSuccess({
                user: {
                  userName: action.userName,
                  accessToken: response.access,
                  refreshToken: response.refresh,
                },
                rememberUser: action.rememberUser,
              }),
            ),
            catchError((error) => of(AuthActions.loginFailure({ error }))),
          ),
      ),
    );
  });

  explicitLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.explicitLogin),
      map((action) =>
        AuthActions.loginSuccess({
          user: {
            userName: 'Explicit login',
            accessToken: action.token,
            refreshToken: '',
          },
          rememberUser: false,
          returnUrl: action.returnUrl,
          params: action.params,
          ignoreSideEffects: true,
        }),
      ),
    );
  });

  refreshToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      concatLatestFrom(() => this.store.select(authFeature.selectUser)),
      filter((user) => !!user),
      exhaustMap(([action, user]) =>
        this.userApi
          .tokenRefreshCreate(
            new TokenRefresh({
              access: user!.accessToken,
              refresh: user!.refreshToken,
            }),
          )
          .pipe(
            map((response) =>
              AuthActions.tokenRefreshed({
                accessToken: response.access,
                refreshToken: response.refresh,
                isRoutine: action.isRoutine,
              }),
            ),
            catchError((error) =>
              of(AuthActions.loginFailure({ error: undefined })),
            ),
          ),
      ),
    );
  });

  rememberUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        filter((action) => action.rememberUser),
        tap((action) =>
          localStorage.setItem(
            this.userPersistentKey,
            JSON.stringify(action.user),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => localStorage.removeItem(this.userPersistentKey)),
      map(() => AdminActions.reload()),
    );
  });

  notSchedulledTokenRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.tokenRefreshed),
      filter((action) => !action.isRoutine),
      map(() => AuthActions.forceUserStateCheck()),
    );
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map((action) =>
        AuthActions.navigateToApp({
          returnUrl: action.returnUrl,
          params: action.params,
          ignoreSideEffects: action.ignoreSideEffects,
        }),
      ),
    );
  });

  loginFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginFailure),
      map(() => AuthActions.forceLogin()),
    );
  });

  forceUserStateCheck$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forceUserStateCheck),
      concatLatestFrom(() => this.store.select(authFeature.selectUser)),
      map(([, user]) =>
        user ? AuthActions.navigateToApp({}) : AuthActions.forceLogin(),
      ),
    );
  });

  navigateToApp$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.navigateToApp),
        filter(() =>
          ['/', '/auth/login', '/auth/explicitLogin'].some(
            (path) => path === window.location.pathname,
          ),
        ),
        switchMap((action) =>
          this.navigateRoot(action.returnUrl ?? 'admin', action.params),
        ),
      );
    },
    { dispatch: false },
  );

  forceLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.forceLogin),
        switchMap(() => this.navigateRoot('auth')),
      );
    },
    { dispatch: false },
  );

  resetPassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resetPassword),
        switchMap((action) =>
          this.userApi.forgotCreate(new Email({ email: action.email })).pipe(
            tapResponse(
              () =>
                this.ui.showMessage(
                  'Password reset instructions have been sent to your email',
                ),
              () => this.ui.showErrorMessage(),
            ),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  setNewPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setNewPassword),
      switchMap((action) =>
        this.userApi
          .forgotTokenValidationCreate(
            new ForgotTokenValidation({
              token: action.token,
              email: action.email,
              new_password: action.password,
            }),
          )
          .pipe(
            map(() => {
              this.ui.showMessage('Password changed successfully');
              return AuthActions.forceLogin();
            }),
            catchError(() => {
              this.ui.showErrorMessage();
              return EMPTY;
            }),
          ),
      ),
    );
  });

  fetchLoginImages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.fetchLoginImages),
      exhaustMap(() =>
        this.toolsApi
          .getGdriveImagesRetrieve()
          .pipe(
            map((res) =>
              AuthActions.loginImagesFetchSuccess({ images: res.image_links }),
            ),
          ),
      ),
    );
  });

  prefillFreshdesk$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess, AuthActions.restoreUser),
        tap((action) =>
          (window as any).FreshworksWidget('identify', 'ticketForm', {
            email: action.user.userName,
          }),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private userApi: UserApi,
    private toolsApi: ToolsApi,
    private ui: UiService,
  ) {}

  private navigateRoot(url: string, params: any = undefined) {
    if (params) {
      url = `${url}?${new URLSearchParams(params).toString()}`;
    }
    return this.router.navigateByUrl(url, { replaceUrl: true });
  }
}
