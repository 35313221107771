{
  "name": "sigrow-admin-portal-v3",
  "version": "1.1.7",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build.dev": "ng build -c development",
    "build.test": "ng build",
    "bump.version": "npm version patch && git push && git push --tags",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.0.4",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/material": "^17.1.2",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@ngrx/component": "^17.0.1",
    "@ngrx/effects": "^17.0.1",
    "@ngrx/operators": "^17.1.0",
    "@ngrx/store": "^17.0.1",
    "@ngrx/store-devtools": "^17.0.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/lodash": "^4.14.202",
    "@types/plotly.js-dist-min": "^2.3.4",
    "ag-grid-angular": "^31.0.1",
    "chart.js": "^4.3.0",
    "chartjs-adapter-dayjs-4": "^1.0.4",
    "chartjs-chart-matrix": "^2.0.1",
    "chartjs-plugin-annotation": "^3.0.1",
    "chartjs-plugin-autocolors": "^0.2.2",
    "chartjs-plugin-zoom": "^2.1.0",
    "dayjs": "^1.11.10",
    "file-saver": "^2.0.5",
    "immer": "^10.0.3",
    "lodash": "^4.17.21",
    "nanoid": "^5.0.6",
    "ng2-charts": "^5.0.4",
    "ngx-mat-select-search": "^7.0.7",
    "plotly.js-dist-min": "^2.35.3",
    "prettier": "^3.2.5",
    "regression": "^2.0.1",
    "rxjs": "~7.8.0",
    "swiper": "^11.1.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.2",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cli": "^17.0.2",
    "@angular/compiler-cli": "^17.0.0",
    "@ngrx/eslint-plugin": "^17.0.1",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "@types/regression": "^2.0.6",
    "@typescript-eslint/eslint-plugin": "6.13.1",
    "@typescript-eslint/parser": "6.13.1",
    "autoprefixer": "^10.4.16",
    "eslint": "^8.54.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.32",
    "tailwindcss": "^3.3.6",
    "typescript": "~5.2.2"
  },
  "packageManager": "yarn@1.22.22+sha1.ac34549e6aa8e7ead463a7407e1c7390f61a6610"
}
