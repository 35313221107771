import {
  IChartCommentConfig,
  IChartUomConfig,
  IDashboardConfig,
  IDevice,
  ITooltip,
  Selectable,
  emptyChartData,
  pointVariables,
  recognitionVariables,
} from './../../../../model/dashboard';
import { AdminActions } from './../../../../state/admin/actions';
import { adminFeature } from './../../../../state/admin/feature';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { cloneDeep, isNumber, remove } from 'lodash';
import {
  ChartsFavouriteResponseItem,
  IChartsCommentItem,
  IChartsFavouriteResponseItem,
  IGetCurrentCamPoint,
  IVariableToDisplay,
} from '../../../../api/api-sdk';
import { CameraViewType, ICameraImage } from '../../../../model/camera';
import { DateRange, dateRanges } from '../../../../model/dateRange';

import _ from 'lodash';
import { DashboardUtils } from '../../../../utils/dashboard';
import { DeviceUtils } from '../../../../utils/device';
import { StateUtils } from '../../../../utils/state';
import { NodesActions } from '../../nodes/state/nodes.actions';
import {
  DashboardCacheType,
  ICameraRecognitionConfig,
  ICameraVariable,
  IChartData,
} from './../../../../model/dashboard';
import { DashboardActions } from './dashboard.actions';

export interface IChartComment {
  chartX: number;
  chartY: number;
  text: string;
  color: string;
}

export interface IDashboardState extends IDashboardConfig {
  dateRange: DateRange;
  chartData: IChartData;
  chartColorsMap: Map<string, string>;
  chartUomConfigs: { [id: string]: IChartUomConfig };
  cameraImagesTimestamp: number;
  dashboardCameraImagesToDisplay: ICameraImage[];
  standaloneCameraImagesToDisplay: ICameraImage[];
  cache: Map<string, any>;
  currentTooltips: ITooltip[];
  imagesDisplayed: boolean;
  chartComments: IChartsCommentItem[];
  expandedChartComments: IChartsCommentItem[];
  chartFavourites: IChartsFavouriteResponseItem[];
}

export const initialState: IDashboardState = {
  dateRange: dateRanges[0],
  devices: [],
  variables: [],
  recognitionVariables: [],
  pointVariables: [],
  points: [],
  customPoints: [],
  recognitions: [],
  chartData: emptyChartData,
  chartColorsMap: new Map(),
  chartUomConfigs: {},
  cameraImagesTimestamp: 0,
  dashboardCameraImagesToDisplay: [],
  standaloneCameraImagesToDisplay: [],
  cache: new Map(),
  currentTooltips: [],
  imagesDisplayed: false,
  chartComments: [],
  expandedChartComments: [],

  // TODO: move to it's own state
  chartFavourites: [],
};

export const reducer = createReducer(
  initialState,
  on(
    AdminActions.activeLocationChanged,
    (): IDashboardState => ({ ...initialState }),
  ),
  on(DashboardActions.regenerateChartSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.chartData = action.result.data as any;
      draft.chartColorsMap = action.result.colorsMap;
    }),
  ),
  on(DashboardActions.refreshDashboardCameraImagesSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.dashboardCameraImagesToDisplay = action.images;
    }),
  ),
  on(DashboardActions.refreshStandaloneCameraImagesSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.standaloneCameraImagesToDisplay = action.images;
    }),
  ),
  on(DashboardActions.dateRangeChanged, (state, action) =>
    produce(state, (draft) => {
      draft.dateRange = action.dateRange;
    }),
  ),
  on(DashboardActions.toggleDevice, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.devices, action.device);
    }),
  ),
  on(DashboardActions.focusDevice, (state, action) =>
    produce(state, (draft) => {
      draft.devices = [...action.devices];
      draft.variables = [...action.variables];
      draft.pointVariables = [...action.pointVariables];
      draft.points = [];
      draft.imagesDisplayed = false;
    }),
  ),
  on(DashboardActions.toggleVariable, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.variables, action.variable);
    }),
  ),
  on(DashboardActions.toggleRecognitionVariable, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.recognitionVariables, action.variable);
    }),
  ),
  on(DashboardActions.togglePointVariable, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.pointVariables, action.variable);
    }),
  ),
  on(DashboardActions.togglePoint, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(
        draft.points,
        action.point,
        (p) => p.id === action.point.id,
      );
    }),
  ),
  on(DashboardActions.toggleCustomPoint, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.customPoints, action.customPoint);
    }),
  ),
  on(DashboardActions.toggleRecognition, (state, action) =>
    produce(state, (draft) => {
      const removed = remove(
        draft.recognitions,
        (r) =>
          r.cameraId === action.config.cameraId &&
          r.recognitionType === action.config.recognitionType,
      );
      if (!removed.length) {
        draft.recognitions.push(action.config);
      }
    }),
  ),
  on(DashboardActions.chartHover, (state, action) =>
    produce(state, (draft) => {
      draft.cameraImagesTimestamp = action.timestamp ?? 0;
    }),
  ),
  on(DashboardActions.cacheUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.cache.set(action.key, action.value);
    }),
  ),
  on(DashboardActions.chartUomConfigChanged, (state, action) =>
    produce(state, (draft) => {
      if (
        isNumber(action.config.minValue) ||
        isNumber(action.config.maxValue)
      ) {
        draft.chartUomConfigs[action.uom] = action.config;
      } else {
        delete draft.chartUomConfigs[action.uom];
      }
    }),
  ),
  on(DashboardActions.applyChartFavourite, (state, action): IDashboardState => {
    const chartFavourite = state.chartFavourites.find(
      (f) => f.id === action.id,
    );

    if (!chartFavourite) {
      return state;
    }

    return {
      ...state,
      ...chartFavourite.configs,
      dateRange:
        dateRanges.find((dr) => dr.name === chartFavourite.date_range_name) ??
        dateRanges[0],
    };
  }),
  on(DashboardActions.fetchChartFavoritesSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.chartFavourites = action.items;
    }),
  ),
  on(DashboardActions.createChartFavouriteSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.chartFavourites.push(action.item);
    }),
  ),
  on(DashboardActions.updateChartFavourite, (state, action) =>
    produce(state, (draft) => {
      const index = draft.chartFavourites.findIndex(
        (cf) => cf.id === action.id,
      );

      if (index < 0) {
        return;
      }

      draft.chartFavourites[index] = new ChartsFavouriteResponseItem({
        ...draft.chartFavourites[index],
        date_range_name: state.dateRange.name,
        configs: DashboardUtils.getDashboardConfigFromState(state),
      });
    }),
  ),
  on(DashboardActions.toggleChartFavouriteDefault, (state, action) =>
    produce(state, (draft) => {
      const index = draft.chartFavourites.findIndex(
        (cf) => cf.id === action.id,
      );

      if (index < 0) {
        return;
      }

      draft.chartFavourites[index] = new ChartsFavouriteResponseItem({
        ...draft.chartFavourites[index],
        is_default: action.isDefault,
      });
    }),
  ),
  on(DashboardActions.deleteChartFavourite, (state, action) =>
    produce(state, (draft) => {
      remove(draft.chartFavourites, (cf) => cf.id === action.id);
    }),
  ),
  on(DashboardActions.updateCurrentTooltips, (state, action) =>
    produce(state, (draft) => {
      draft.currentTooltips = action.tooltips;
    }),
  ),
  on(DashboardActions.toggleImagesDisplay, (state, action) =>
    produce(state, (draft) => {
      draft.imagesDisplayed = !draft.imagesDisplayed;
    }),
  ),
  // TODO: refactor that and use pipe for device name everywhere
  on(NodesActions.nodeUpdated, (state, action) =>
    produce(state, (draft) => {
      StateUtils.updateArrayItem(
        draft.devices,
        (r) => r.remote_id === action.nodeId,
        { name: action.form.name },
      );
    }),
  ),
  on(DashboardActions.chartCommentSaveSucceeded, (state, action) =>
    produce(state, (draft) => {
      draft.chartComments.push(action.comment);
    }),
  ),
  on(DashboardActions.chartCommentDeleted, (state, action) =>
    produce(state, (draft) => {
      remove(draft.chartComments, (c) => c.id === action.id);
    }),
  ),
  on(DashboardActions.chartCommentsFetched, (state, action) =>
    produce(state, (draft) => {
      draft.chartComments = action.comments;
    }),
  ),
  on(DashboardActions.expandChartComment, (state, action) =>
    produce(state, (draft) => {
      draft.expandedChartComments = [action.comment];
    }),
  ),
  on(DashboardActions.collapseChartComment, (state, action) =>
    produce(state, (draft) => {
      draft.expandedChartComments = [];
    }),
  ),
  on(DashboardActions.focusChartComment, (state, action) => {
    const commentConfig = action.comment.configs as IChartCommentConfig;
    return {
      ...state,
      ...commentConfig.dashboardConfig,
      dateRange: DateRange.fromStartEnd(
        dayjs(commentConfig.startTimestamp),
        dayjs(commentConfig.endTimestamp),
      ),
    };
  }),
);

export const dashboardFeature = createFeature({
  name: 'dashboard',
  reducer,
  extraSelectors: ({
    selectChartData,
    selectCache,
    selectDevices,
    selectRecognitionVariables,
    selectPointVariables,
    selectRecognitions,
    selectCustomPoints,
    selectVariables,
    selectPoints,
    selectChartUomConfigs,
    selectChartFavourites,
    selectCurrentTooltips,
    selectDashboardCameraImagesToDisplay,
    selectStandaloneCameraImagesToDisplay,
  }) => ({
    selectChatJsBinding: createSelector(selectChartData, (chartData) =>
      cloneDeep(chartData),
    ),
    selectCacheByType: (type: DashboardCacheType) =>
      createSelector(selectCache, (cache) => cache.get(type)),
    selectRecognitionVariables: createSelector(
      adminFeature.selectAllDevices,
      selectDevices,
      (allDevices, selectedDevices) =>
        selectedDevices.some(
          (sd) =>
            !!allDevices.find((ad) => ad.remote_id === sd.remote_id)
              ?.flower_recognition,
        )
          ? recognitionVariables
          : [],
    ),
    selectPointVariables: createSelector(selectDevices, (devices) =>
      devices.some((d) => !!d.thermal_camera_id) ? pointVariables : [],
    ),
    selectDeviceCustomPoints: (deviceId: number) =>
      createSelector(selectCustomPoints, (points) =>
        points.filter((p) => p.cameraId === deviceId),
      ),
    selectChartUomConfigByName: (uom: string) =>
      createSelector(selectChartUomConfigs, (configs) => configs[uom]),
    selectDashboardDevices: createSelector(
      adminFeature.selectAllDevicesForActiveLocation,
      selectDevices,
      (devices, activeDevices) =>
        devices.map(
          (d) =>
            ({
              ...d,
              selected: activeDevices.some(
                (ad) =>
                  ad.remote_id === d.remote_id &&
                  ad.thermal_camera_id === d.thermal_camera_id,
              ),
            }) satisfies Selectable<IDevice>,
        ),
    ),
    selectChartFavouritesForActiveLocation: createSelector(
      adminFeature.selectActiveLocation,
      selectChartFavourites,
      (activeLocation, cfs) =>
        _(cfs)
          .filter(
            (cf) => cf.central_id === activeLocation?.location?.central_id,
          )
          .orderBy((cf) => cf.is_default, 'desc')
          .value(),
    ),
    selectChartFavouriteById: (id: number) =>
      createSelector(selectChartFavourites, (cfs) =>
        _(cfs).find((cf) => cf.id === id),
      ),
    selectGroupedTooltips: createSelector(selectCurrentTooltips, (tooltips) =>
      _(tooltips)
        .groupBy((t) => `${t.title} • ${dayjs(t.x).format('DD/MM HH:mm:ss')}`)
        .map((tooltips, title) => ({ title, tooltips }))
        .value(),
    ),
    selectCameraImagesGrouped: (standalone = false) =>
      createSelector(
        selectDashboardCameraImagesToDisplay,
        selectStandaloneCameraImagesToDisplay,
        adminFeature.selectAllDevicesForActiveLocation,
        (dashboardImages, standaloneImages, devices) => {
          const liveCameraImages = _(
            standalone ? standaloneImages : dashboardImages,
          )
            .groupBy((i) => i.camera.thermal_camera_id)
            .map((images, cameraId) => ({ cameraId, images }))
            .orderBy((ci) => ci.cameraId)
            .value();

          const allLocationCameras = DeviceUtils.applyCamerasFilter(devices);

          const offlineCameras =
            allLocationCameras.filter(
              (c) =>
                !liveCameraImages.some(
                  (lc) => lc.cameraId === c.thermal_camera_id.toString(),
                ),
            ) ?? [];

          const offlineCameraImages: {
            cameraId: string;
            images: ICameraImage[];
          }[] = [];

          for (const offlineCamera of offlineCameras) {
            offlineCameraImages.push({
              cameraId: offlineCamera.thermal_camera_id.toString(),
              images: [
                {
                  id: -1,
                  camera: offlineCamera,
                  viewType: CameraViewType.TemperaturePlus,
                  imageUrl: '',
                  timestamp: '',
                },
                {
                  id: -1,
                  camera: offlineCamera,
                  viewType: CameraViewType.StomataPlus,
                  imageUrl: '',
                  timestamp: '',
                },
              ],
            });
          }

          return [...liveCameraImages, ...offlineCameraImages];
        },
      ),
    // TODO: refactor this
    isDeviceSelected: (item: IDevice) =>
      createSelector(selectDevices, (items) =>
        items.some(
          (i) =>
            i.remote_id === item.remote_id &&
            i.thermal_camera_id === item.thermal_camera_id,
        ),
      ),
    isVarSelected: (item: IVariableToDisplay) =>
      createSelector(selectVariables, (items) =>
        items.some((i) => i.name === item.name),
      ),
    isRecVarSelected: (item: ICameraVariable) =>
      createSelector(selectRecognitionVariables, (items) =>
        items.some((i) => i.name === item.name),
      ),
    isPointVarSelected: (item: ICameraVariable) =>
      createSelector(selectPointVariables, (items) =>
        items.some((i) => i.name === item.name),
      ),
    isPointSelected: (item: IGetCurrentCamPoint) =>
      createSelector(selectPoints, (items) =>
        items.some((i) => i.id === item.id),
      ),
    isRecSelected: (item: ICameraRecognitionConfig) =>
      createSelector(selectRecognitions, (items) =>
        items.some(
          (i) =>
            i.cameraId === item.cameraId &&
            i.recognitionType === item.recognitionType,
        ),
      ),
    isAnyCameraSelected: createSelector(
      selectDevices,
      (devices) => DeviceUtils.applyCamerasFilter(devices).length > 0,
    ),
  }),
});

// TODO: make predicate mandatory and merge predicate with is[entity]Selected selectors
function toggleArrayItem<T>(
  array: T[],
  item: T,
  predicate?: (item: T) => boolean,
) {
  const removed = remove(
    array,
    predicate ? (i) => predicate(i) : (item as any),
  );
  if (!removed.length) {
    array.push(item);
  }
  return removed.length;
}
