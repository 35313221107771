import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  IChartsCommentItem,
  IChartsFavouriteResponseItem,
  IGetCurrentCamPoint,
  IVariableToDisplay,
} from '../../../../api/api-sdk';
import { ICameraImage } from '../../../../model/camera';
import {
  ICameraRecognitionConfig,
  ICameraVariable,
  IChartUomConfig,
  IChatGenerationResult,
  ICustomPoint,
  IDevice,
  ISavedDashboardConfig,
  ITooltip,
} from '../../../../model/dashboard';
import { DateRange } from '../../../../model/dateRange';
import { IChartComment } from './dashboard.feature';

export const DashboardActions = createActionGroup({
  source: 'Dashboard',
  events: {
    dateRangeChanged: props<{ dateRange: DateRange }>(),
    reapplyCurrentDateRange: emptyProps(),
    toggleDevice: props<{ device: IDevice }>(),
    toggleVariable: props<{ variable: IVariableToDisplay }>(),
    toggleRecognitionVariable: props<{ variable: ICameraVariable }>(),
    togglePointVariable: props<{ variable: ICameraVariable }>(),
    togglePoint: props<{ point: IGetCurrentCamPoint }>(),
    toggleCustomPoint: props<{ customPoint: ICustomPoint }>(),
    toggleRecognition: props<{ config: ICameraRecognitionConfig }>(),
    focusDevice: props<{
      devices: IDevice[];
      variables: IVariableToDisplay[];
      points: IGetCurrentCamPoint[];
      pointVariables: ICameraVariable[];
    }>(),
    chartClick: props<{
      chartX: number;
      chartY: number;
    }>(),
    chartHover: props<{
      timestamp: number;
      clientX: number;
      clientY: number;
    }>(),
    chartCommentAdded: props<{ comment: IChartComment }>(),
    chartCommentDeleted: props<{ id: number }>(),
    expandChartComment: props<{ comment: IChartsCommentItem }>(),
    collapseChartComment: emptyProps(),
    chartCommentSaveSucceeded: props<{ comment: IChartsCommentItem }>(),
    chartCommentsFetched: props<{ comments: IChartsCommentItem[] }>(),
    focusChartComment: props<{ comment: IChartsCommentItem }>(),
    refresh: emptyProps(),
    regenerateChart: emptyProps(),
    regenerateChartSuccess: props<{ result: IChatGenerationResult }>(),
    refreshDashboardCameraImages: emptyProps(),
    refreshDashboardCameraImagesSuccess: props<{ images: ICameraImage[] }>(),
    refreshStandaloneCameraImages: emptyProps(),
    refreshStandaloneCameraImagesSuccess: props<{ images: ICameraImage[] }>(),
    toggleImagesDisplay: emptyProps(),
    cacheUpdated: props<{ key: string; value: unknown }>(),
    chartUomConfigChanged: props<{ uom: string; config: IChartUomConfig }>(),

    restoreSavedConfigs: props<{ savedConfigs: ISavedDashboardConfig[] }>(),

    fetchChartFavorites: emptyProps(),
    fetchChartFavoritesSuccess: props<{
      items: IChartsFavouriteResponseItem[];
    }>(),
    createChartFavourite: props<{ name: string }>(),
    createChartFavouriteSuccess: props<{
      item: IChartsFavouriteResponseItem;
    }>(),
    updateChartFavourite: props<{ id: number }>(),
    deleteChartFavourite: props<{ id: number }>(),
    toggleChartFavouriteDefault: props<{ id: number; isDefault: boolean }>(),
    applyChartFavourite: props<{ id: number }>(),

    updateCurrentTooltips: props<{ tooltips: ITooltip[] }>(),
    shiftDateRange: props<{ delta: number }>(),
  },
});
